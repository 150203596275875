<template>
  <div class="pa-4">
    <v-card class="mt-2">
      <div v-if="$route.params.action!='view'">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mx-3 py-3">
              <v-col cols="3">
                <v-text-field :rules="reqRule" outlined dense hide-details="auto" label="Batch" v-model="batch" :readonly="($route.params.batch!=null)"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-menu v-model="menuDateProd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateProd" label="Hari Produksi" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="reqRule" hide-details="auto" dense></v-text-field>
                  </template>
                  <v-date-picker v-model="dateProd" @input="onDateChanged()"></v-date-picker>
                </v-menu>
              </v-col>                    
              <v-col cols="3">
                <v-menu v-model="menuDateDeliv" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateDeliv" label="Hari Kirim" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="reqRule" hide-details="auto" dense></v-text-field>
                  </template>
                  <v-date-picker v-model="dateDeliv" @input="menuDateDeliv = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md2 class="text-right">
                <v-btn color="" depressed small to="/requestprod"><v-icon small>mdi-arrow-left</v-icon> Back</v-btn>
              </v-col>
          </v-row>
          <v-row class="mx-5">
            <v-spacer></v-spacer>
            <v-flex md4 class="">
              <v-file-input dense label="Upload CSV" @change="onFileChange" prepend-icon="mdi-upload" :disabled="!validUpload"></v-file-input>
            </v-flex>
                
          </v-row>
        </v-form>
      </div>
      <div v-else>
        <v-row class="mx-3">
          <v-spacer></v-spacer>
          <v-col md2 class="text-right">
            <v-btn color="" depressed small @click="$router.go(-1)"><v-icon small>mdi-arrow-left</v-icon> Back</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-tabs v-model="tabCategory">
        <v-tab v-for="category of categories" :key="category">Request {{category}} </v-tab>
        <v-tab-item v-for="(category,icat) in categories" :key="icat" :transition="false" class="pr-4">
          <v-tabs vertical v-model="tabDate" class="ma-2 elevation-1">
              <v-tab v-for="(day, idx) in prodDates" :key="idx">{{dateFiltered(day)}}</v-tab>
              <v-tab-item v-for="(day, idx) in prodDates" :key="idx" :transition="false" class="pr-5">
                  <v-tabs class="ma-2 elevation-1">
                      <v-tab v-for="(type, idx) in types" :key="idx">{{type.code}}</v-tab>
                      <v-tab-item v-for="(type, idx) in types" :key="idx" :transition="false">
                          <v-card elevation="1" class="ma-2 mr-3 pt-3" v-if="csvData">
                            <v-card-text>

                              <v-simple-table fixed-header height="350px">
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">Volume</th>
                                      <th class="text-left">Variant</th>
                                      <th class="text-left" v-for="(cust, cidx) in uniqueCustomers" :key="cidx">{{cust.customer_name}}</th>
                                      <th class="text-left">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(variant, vidx) in uniqueVariants" :key="vidx">
                                      <td>{{variant.size}} ml</td>
                                      <td>{{variant.name}}</td>
                                      {{void(total=0)}}
                                      <td v-for="(cust, cidx) in uniqueCustomers" :key="cidx" :set="val=mapData(cust.code, variant.code, type.code, category, day)">
                                        {{void(total=total+val)}}
                                        {{val}}
                                      </td>
                                      <td>
                                        {{total}}
                                      </td>
                                    </tr>

                                  </tbody>
                                </template>
                              </v-simple-table>                              
                            </v-card-text>
                          </v-card>
                      </v-tab-item>
                  </v-tabs>
                  <div class="text-right" v-if="csvData && $route.params.action!='view'">
                    <v-col >
                      <v-btn color="primary" depressed small @click="saveOrders(category)">Save</v-btn>
                    </v-col>
                  </div>
              </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <!-- <v-tab-item>
          <request-prod category='ZIRCON'></request-prod>
        </v-tab-item> -->
      </v-tabs>
    </v-card>
    <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
    <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
  </div>
</template>

<script>
// import RequestProd from '../components/RequestProd.vue'
// import { VueCsvImport } from 'vue-csv-import';
import moment from 'moment'

export default {
  // components: { RequestProd },
  data() {
    return {
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: false,
      saved: false,
      saveError: false,
      parseCsv: null,
      variants: [],
      categories: ['B2B', 'ZIRCON'],
      days: [],
      types: [],
      orders: [],
      uniqueCustomers: [],
      uniqueVariants: [],
      csvData: null,
      menuDateProd: false,
      menuDateDeliv: false,
      dateProd: null,
      dateDeliv: null,
      // dateProd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // dateDeliv: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      batch: null,
      customers: null,
      tabCategory: 0,
      tabDate: 0,
      prodDates: ['ALL'] // [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)]
    }
  },
  computed: {
    miscCodes() {
      return this.$store.state.misccode.objs
    },
    validUpload() {
      return this.batch && this.dateProd && this.dateDeliv
    }
  },
  filters: {
      sdate: function (date) {
          return moment(date).format('ddd D/MM');
      }
  },
  async created() {
    this.batch = this.$route.params.batch
    this.variants = (await this.$store.dispatch('variant/getObjs')).data.data
    this.customers = (await this.$store.dispatch('customer/getObjs')).data.data
    this.types = this.miscCodes.filter(element => {
        return element.type=='Order Type'
    })
    this.types.unshift({code: 'ALL', description: 'ALL'})

    // Load esisting data from API
    if (this.$route.params.action=='ppic' || this.$route.params.action=='view') {
      this.batch = this.$route.params.batch
      let temp = (await this.$store.dispatch('getObjs', {apidomain:'others/planningorders', parameter:''})).data.data
      this.prodDates = [...new Set(temp.map(item => item.production_date))];
      this.prodDates.sort();
      console.log(this.prodDates)
      var load = this.csvData = this.jsonToCsv(temp)
      this.loadCsvData(load)
    }
  },
  methods: {
    onDateChanged() {
      this.menuDateProd = false; 
      this.dateDeliv = this.dateProd
    },
    onClear() {
      console.log(this.uniqueCustomers, this.uniqueVariants)
      this.csvData = null
      this.uniqueCustomers = []
      this.uniqueVariants = []
    },
    dateFiltered(date) {
      if (date=='ALL') return date;
      return moment(date).format('ddd D/MM');
      
    },
    jsonToCsv(json) {
      console.log(json)
      var csv = []
      json.forEach(obj => {
        obj.details.forEach(dt => {
          csv.push({
            ORDERID: obj.order_id,
            OUTLET_CODE: obj.customer_code,
            KODE_PRODUK: dt.variant_code,
            QTY: Number(dt.qty_ordered),
            TYPE: dt.type,
            CATEGORY: obj.order_category,
            DATE: obj.production_date
          })
        })
      })
      return csv
    },
    generateProdNo(date) {
      return 'PN' + date.substr(2,2) + date.substr(5,2) + date.substr(8,2)
    },
    async saveOrders(category) {
      await this.$refs.form.validate();
      if (!this.valid) return;      
      this.csvData.sort((a,b) => parseFloat(a.OUTLET_CODE) - parseFloat(b.OUTLET_CODE))
      var oid = '';
      var payload = [];
      var i = -1;
      this.csvData.forEach(element => {
        // console.log(element)
        var order_details = {
          variant_code: element.KODE_PRODUK,
          type: element.TYPE,
          qty_ordered: element.QTY
        }
        if (oid != element.ORDERID) {
          i++;
          oid = element.ORDERID
          payload.push({
               order_batch: this.batch,
               order_id: element.ORDERID,
               prod_no: this.generateProdNo(this.dateProd),
               production_date: this.dateProd,
               order_category: category,
               delivery_date: this.dateDeliv,
               customer_code: element.OUTLET_CODE,
               order_details: [order_details]
          })
        }
        else {
          payload[i].order_details.push(order_details)
        }
      });
      console.log(payload)
      let url = this.$store.state.config.apiHost + "orders"
      var response
      try {
        response = await this.$axios.post(url, JSON.stringify({orders:payload}), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        if (response.data.status=='success') {
          console.log(response)
          // this.onClear()
          this.saved = true
        }
      }
      catch (e) {
        console.log(e)
        this.saveError = true
      }
    },
    mapData(cust, variant, otype, category, date) {
      // console.log(date)
      var data = null
      var potype = otype.toUpperCase()
      // console.log(this.csvData)
      if (this.$route.params.action==null || this.$route.params.action=='edit'){
        if (potype == 'ALL') data = this.csvData.filter(el => {return (el.OUTLET_CODE==cust && el.KODE_PRODUK==variant && el.CATEGORY==category)})
        else  data = this.csvData.filter(el => {return (el.OUTLET_CODE==cust && el.KODE_PRODUK==variant && el.TYPE==otype && el.CATEGORY==category)})
      }
      else {
        if (potype == 'ALL') data = this.csvData.filter(el => {return (el.OUTLET_CODE==cust && el.KODE_PRODUK==variant && el.CATEGORY==category && el.DATE==date)})
        else  data = this.csvData.filter(el => {return (el.OUTLET_CODE==cust && el.KODE_PRODUK==variant && el.TYPE==otype && el.CATEGORY==category && el.DATE==date)})
      }
      // console.log(data)
      var totalQty = 0
      if (data.length) {
        totalQty = data.reduce((acc, curr) => acc + curr.QTY, 0)
      }
      // console.log(totalQty)
      return totalQty
    },
    loadCsvData(load) {

      const uniqueCustomers = [...new Set(load.map(item => item.OUTLET_CODE))];
      const uniqueVariants = [...new Set(load.map(item => item.KODE_PRODUK))]; 
      uniqueVariants.forEach(element => {
        var variant = this.variants.find(el => el.code === element)
        variant.qty_nonexp = 0
        this.uniqueVariants.push(variant)
      });
      uniqueCustomers.forEach(element => {
        const customer = this.customers.find(el => el.code === element)
        this.uniqueCustomers.push(customer)
      });
      console.log(uniqueVariants, this.uniqueVariants)
      this.uniqueVariants.sort((a,b) => parseFloat(a.size) - parseFloat(b.size));
      console.log(this.uniqueVariants)
    },
    async onFileChange(e) {
      if (e==null) { // on clear text
        console.log('cleared')
        return this.onClear()
      }
      var input = await this.getCsvFile(e)
      var load = this.csvData = this.csvToJson(input)
      if (load[0].ORDERID==null) return
      load.forEach(obj => {
        obj.CATEGORY = this.categories[this.tabCategory]
        obj.DATE = this.dateProd[this.tabDate]
      })
      this.loadCsvData(load)
    },
    getCsvFile(file) {
      return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function () {
              resolve(reader.result);
          };
          reader.onerror = function (error) {
              reject('Error: ', error);
          };
      })      
    },
    csvToJson (text, headers, quoteChar = '"', delimiter = ',') {
      const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

      const match = line => [...line.matchAll(regex)]
        .map(m => m[2])  // we only want the second capture group
        .slice(0, -1);   // cut off blank match at the end

      const lines = text.split('\n');
      const heads = headers ?? match(lines.shift());

      return lines.map(line => {
        return match(line).reduce((acc, cur, i) => {
          // Attempt to parse as a number; replace blank matches with `null`
          const val = cur.length <= 0 ? null : Number(cur) || cur;
          const key = heads[i] ?? `extra_${i}`;
          return { ...acc, [key]: val };
        }, {});
      });
    }     
  }
}
</script>
